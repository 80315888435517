import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // 404 페이지
    path: "/404",
    name: "404",
    component: () => import ( /* webpackChunkName: "404" */ "../views/404.vue"),
  },
  { // 존재하지 않는 페이지 404로 리다이렉트
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 맨 위로
  scrollBehavior(to, from, savedPosition) {
    if (to.params.savePosition || savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior : 'smooth',
        top: 80,
      }
    } else {
      window.scrollTo({top : 0, behavior : 'smooth'})
    }
  },
})

export default router
