import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import slide from './directive/slide';
import circleMotion from './directive/circleMotion';
import circleMotionCustom from './directive/circleMotionCustom';

const app = createApp(App);
app.directive("slide", slide);
app.directive("circleMotion", circleMotion);
app.directive("circleMotionCustom", circleMotionCustom);
app.use(store).use(router).mount('#app')
