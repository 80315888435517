<template>
	<header class="gnb" id="gnb">
		<nav class="c_inner">
			<div class="gnb_logo">
				<router-link to="#home">
					<img class="ci" src="@/assets/image/common/logo_white.svg" alt="logo_ci" />
				</router-link>
			</div>
			<nav class="gnb_menu">
				<router-link
					class="anchor_home on"
					to="#home"
				>home</router-link>
				<router-link
					class="anchor_aboutus"
					to="#aboutus"
				>about us</router-link>
				<router-link
					class="anchor_works"
					to="#works"
				>works</router-link>
				<router-link
					class="anchor_contact"
					to="#contact"
				>contact</router-link>
			</nav>
			<button @click="toggleGnb('toggle')" class="btn_ham" id="btn_ham">
				<div class="top"></div>
				<div class="mid"></div>
				<div class="bottom"></div>
			</button>
		</nav>
	</header>
</template>

<script>
export default {
	mounted(){
		window.addEventListener('resize', this.headerResizer);
		window.addEventListener('click', this.outsideClickCheck);
		// window.addEventListener('scroll', this.scrollAnchorCheck);
	},
	unmounted(){
		window.removeEventListener('resize', this.headerResizer);
		window.addEventListener('click', this.outsideClickCheck);
		// window.removeEventListener('scroll', this.scrollAnchorCheck);
	},
	watch:{
		// 다른 페이지로 넘어갈 때 헤더 닫기(뒤로가기 때문)
		$route() {
			this.toggleGnb('remove');
		}
	},
	data(){
		return {
		}
	},
	methods: {
		headerResizer(){	// 창크기가 768보다 넓으면 닫음
			if(window.innerWidth > 768){
				this.toggleGnb('remove');
			}
		},
		outsideClickCheck(e){	// 외부 클릭시 닫음
			if(window.innerWidth > 768) return;
			const gnb = document.getElementById('gnb');
			if(!gnb.classList.contains('on')) return;
			const path = e.path;
			let isContainGnb = false;
			for(let i=0, len=path.length; i<len; i++){
				if(path[i].id==='gnb'){
					isContainGnb = true;
					break;
				}
			}
			if(!isContainGnb){
				this.toggleGnb('remove');
			}
		},
		toggleGnb(action="toggle"){
			const gnb = document.getElementById('gnb');
			const btn_ham = document.getElementById('btn_ham');
			if(action==='toggle'){
				btn_ham.classList.toggle('on');
				gnb.classList.toggle('on');
			} else {
				btn_ham.classList.remove('on');
				gnb.classList.remove('on');
			}
		},
		// scrollAnchorCheck(){
		// 	this.isThrottling = true;
		// 	const home = document.getElementById('home')?.offsetTop;
		// 	const aboutus = document.getElementById('aboutus')?.offsetTop;
		// 	const works = document.getElementById('works')?.offsetTop;
		// 	// const contact = document.getElementById('contact')?.offsetTop;

		// 	document.querySelectorAll('.gnb_menu>*').forEach(el=>el.classList.remove('on'));

		// 	const scrollY = window.scrollY + 81; // router에 적용해놓은 scroll offset + 1 만큼

		// 	if(scrollY >= home && scrollY < aboutus){
		// 		document.querySelector('.anchor_home').classList.add('on');
		// 	} else if(scrollY >= aboutus && scrollY < works){
		// 		document.querySelector('.anchor_aboutus').classList.add('on');
		// 	} else if(scrollY >= works && scrollY < document.body.scrollHeight - window.innerHeight - 100){
		// 		document.querySelector('.anchor_works').classList.add('on');
		// 	} else{
		// 		document.querySelector('.anchor_contact').classList.add('on');
		// 	}
		// }
	}
}
</script>

<style scoped src="@/assets/css/components/header.css">
</style>