<template>
  <div class="c_content_container home" id="home">
    <!-- main_banner 시작 -->
    <section class="c_section main_banner">
      <div class="player_container">
        <!-- <div class="player"></div> -->
        <YouTube
          ref="youtube"
          class="player"
          width='100%'
          height='100%'
          :src="`https://www.youtube.com/watch/${this.videoId}`"
          :vars="playerVars"
          @ready="youtubeReady"
          @playing="()=>console.log('play')"
        />
        <transition name="fade">
          <picture
            v-if="!this.isVideoReady"
            class="thumbnail_image"
          >
            <source media="(min-width:600px)" srcset="@/assets/image/home/thumb_SD.jpg">
            <img src="@/assets/image/home/thumb_HD.jpg" alt="thumbnail">
          </picture>
        </transition>
        <!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fHIn7TrGhvQ?autoplay=1&mute=1&loop=1&playlist=fHIn7TrGhvQ&playsinline=1&controls=0&fs=0&rel=0&modestbranding=1&disablekb=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </div>
      <div class="text_box">
        <div class="c_inner">
          <h1>현실을<br /> 뛰어넘다</h1>
          <p>
            인터렉티브미디어, 증강현실, 가상현실과 메타버스까지.<br />
            비넥츠는 언제나 가장 적확한 기술과 진보된 방식의 구축 과정으로<br />
            한차원 더 높은 수준의 결과에 도달하기 위해 노력하겠습니다.
          </p>
        </div>
      </div>
    </section>
    <!-- main_banner 끝 -->

    <!-- our_clients 시작 -->
    <section class="c_section our_clients">
      <div class="c_inner">
        <h1 class="c_section_title">
          Our Clients
        </h1>
        <swiper class="client_box" @swiper="onSwiper" v-bind="clientSlideOption">
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_samsung.svg" alt="clients_samsung">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_sktelecom.svg" alt="clients_sktelecom">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_lg.svg" alt="clients_lg">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_everland.svg" alt="clients_everland">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_lh.svg" alt="clients_lh">
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/image/home/clients/clients_lotteworld.svg" alt="clients_lotteworld">
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <!-- our_clients 끝 -->

    <!-- aboutus 시작 -->
    <section class="c_section our_services" id="aboutus">
      <div class="c_inner">
        <h1 class="c_section_title">
          Our Services
        </h1>
        <div class="text_box">
          <div v-slide.child="{ percent: 0 }" class="sub_titles">
            <h2 class="c_sub_title">+ AI Digital Human</h2>
            <h2 class="c_sub_title">+ METAVERSE</h2>
            <h2 class="c_sub_title">+ IMMERSIVE DIGITAL EXPERIENCE</h2>
            <h2 class="c_sub_title">+ GAME</h2>
          </div>
          <p v-slide="{ percent: 0 }">
            비넥츠는 인공지능 디지털 휴먼, 메타버스 , 실감형 콘텐츠 , 네트워크 게임 등<br />
            관련 실무 경력 20년이상의 전문가들이 함께 하고 있습니다.
          </p>
        </div>
        <img v-slide="{ percent: 0 }" src="@/assets/image/home/img_service.png" alt="img_service" />
      </div>
    </section>
    <!-- aboutus 끝 -->

    <!-- our_hitory 시작 -->
    <section class="c_section our_hitory">
      <div class="c_inner">
        <h1 class="c_section_title">
          Our History
        </h1>
        <div class="history_box">
          <ol class="hitory_years">
            <li
              v-for="(history, i) in this.histories" :key="i"
              :class="history.year===this.currentHistory ? 'on' : ''"
              class="c_sub_title"
            >
              <span
                @click="()=>{this.currentHistory=history.year}"
              >
                {{history.year}}
              </span>
            </li>
          </ol>

          <ul class="history_content">
            <li
              v-for="(content, i) in this.histories.find(el=>el.year===this.currentHistory).contents"
              :key="i"
            >{{content}}</li>
          </ul>
        </div>
      </div>
    </section>
    <!-- our_hitory 끝 -->

    <!-- our_technology 시작 -->
    <section class="c_section our_technology">
      <div class="c_inner">
        <h1 class="c_section_title">
          Our Technology
        </h1>

        <article class="vr_device">
          <div class="text_box">
            <h2 v-slide class="c_sub_title">
              <img class="tech_num" src="@/assets/image/home/img_num_01.svg" alt="num_01">
              차세대 실감형<br /> 기기 지원
            </h2>
            <p v-slide.vertical>
              차세대 VR , 립모션 , 햅틱 글로브 등
              다양한 실감형 디바이스 지원
            </p>
          </div>
          <div v-circleMotion.child class="device_list">
            <div class="circle">
              <div class="img_box">
                <div class="img_container">
                  <div class="img_wrapper">
                    <img src="@/assets/image/home/img_tech_device01.png" alt="img_tech_device01" />
                  </div>
                </div>
              </div>
            </div>
            <div class="circle">
              <div class="img_box">
                <div class="img_container">
                  <div class="img_wrapper">
                    <img src="@/assets/image/home/img_tech_device02.png" alt="img_tech_device02" />
                  </div>
                </div>
              </div>
            </div>
            <div class="circle">
              <div class="img_box">
                <div class="img_container">
                  <div class="img_wrapper">
                    <img src="@/assets/image/home/img_tech_device03.png" alt="img_tech_device03" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="dev_solution">
          <div v-slide.vertical.child class="solution_list">
            <div class="circle">
              <img src="@/assets/image/home/img_tech_solution01.png" alt="img_tech_solution01" />
            </div>
            <div class="circle">
              <img src="@/assets/image/home/img_tech_solution02.png" alt="img_tech_solution02" />
            </div>
            <div class="circle">
              <img src="@/assets/image/home/img_tech_solution03.png" alt="img_tech_solution03" />
            </div>
            <div class="circle">
              <img src="@/assets/image/home/img_tech_solution04.png" alt="img_tech_solution04" />
            </div>
            <div class="circle">
              <img src="@/assets/image/home/img_tech_solution05.png" alt="img_tech_solution05" />
            </div>
          </div>
          <div class="text_box">
            <h2 v-slide.reverse class="c_sub_title">
              <img class="tech_num" src="@/assets/image/home/img_num_02.svg" alt="num_02">
              언리얼 엔진 5<br />
              파이프라인
            </h2>
            <p v-slide.vertical>
              저비용 고성능, 고퀄리티, 고효율 개발 솔루션 지향
            </p>
          </div>
        </article>

        <article class="metaverse_tech">
          <div class="text_box">
            <h2 v-slide class="c_sub_title">
              <img class="tech_num" src="@/assets/image/home/img_num_03.svg" alt="num_03">
              메타버스 & XR 구축 기술
            </h2>
            <p v-slide.vertical>
              * 메타버스 플랫폼 및 XR 관련 구축에 필요한 모든 요소의 기술을 축적하고 있습니다.
            </p>
          </div>
          <ul v-circleMotionCustom class="tech_list">
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_01.svg" alt="icon_tech_01">
              <h2>OPEN WORLD</h2>
              <p>
                사용자가 가상세계를 자유롭게 탐험하며 구성요소들을 마음대로 바꿀 수 있는 게임 Interface 적용
              </p>
            </li>
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_02.svg" alt="icon_tech_02">
              <h2>AAA GRAPHIC ASSET</h2>
              <p>
                3D 메타버스 환경에 최적화된 그래픽을 통한 고품질의 실감나는 메타 환경 구현
              </p>
            </li>
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_03.svg" alt="icon_tech_03">
              <h2>MMO SERVER</h2>
              <p>
                가상세계에서 멀티플 유저 활동에 제약없이 안정적으로 구동 될 수 있는 최적화 서버 구축
              </p>
            </li>
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_04.svg" alt="icon_tech_04">
              <h2>CROSS PLATFORM</h2>
              <p>
                사용자의 다양한 컴퓨팅 환경에서도 일관적인 사용성이 가능하도록 구현
              </p>
            </li>
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_05.svg" alt="icon_tech_05">
              <h2>META HUMAN</h2>
              <p>언리얼 엔진 픽셀 스트리밍 기반의 '메타휴먼 크리에이터'를 통한 최고수준의 가상(증강)공간 최적화 AI 캐릭터 구현</p>
            </li>
            <li class="circle">
              <img src="@/assets/image/home/icon_tech_06.svg" alt="icon_tech_06">
              <h2>VIRTUAL REALITY</h2>
              <p>VR디바이스 환경에 최적화된 해상도, UI/UX 적용을 통한 몰입감있는 환경 제공</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
    <!-- our_technology 끝 -->

    <!-- works 시작 -->
    <section class="c_section works" id="works">
      <div class="c_inner">
        <div class="category_wrapper">
          <h1 class="c_section_title">
            Works
          </h1>
          <ul class="works_category">
            <li
              :class="currentWork==='all' ? 'on' : ''"
              @click="()=>{this.currentWork = 'all'}"
            >ALL</li>
            <li
              :class="currentWork==='vr' ? 'on' : ''"
              @click="()=>{this.currentWork = 'vr'}"
            >VR</li>
            <li
              :class="currentWork==='inter' ? 'on' : ''"
              @click="()=>{this.currentWork = 'inter'}"
            >Interactive</li>
            <li
              :class="currentWork==='movie' ? 'on' : ''"
              @click="()=>{this.currentWork = 'movie'}"
            >Movie</li>
          </ul>
        </div>
        <transition-group name="list-pop" tag="ul" class="works_list">
          <li
            v-for="(item, i) in this.slicedWorks"
            :key="item.title"
            class="circle list-pop-item"
            @click="openWorkPopup(item)"
          >
            <img :src="item.thumb" :alt="`img_works${i}`">
            <h2>{{item.title}}</h2>
          </li>
        </transition-group>
        <button v-show="!this.showMoreWork && this.filteredWorks.length > 9"
          class="c_button-primary-reverse"
          @click="()=>{ this.showMoreWork=true }"
        >more</button>

        <!-- works_list 누르면 나오는 팝업 -->
        <div v-if="this.workPopupContent" class="c_screen_filter"></div>
        <transition name="fade-pop">
          <div
            v-if="this.workPopupContent"
            class="workPopup"
          >
            <div class="c_inner">
              <button class="btn_close"
                @click="closeWorkPopup"
              >
                <img src="@/assets/image/home/btn_close.svg" alt="close" />
              </button>
              <h2>{{workPopupContent.title}}</h2>
              <swiper class="slide_container" v-bind="popupSlideOption">
                <swiper-slide
                  v-for="(item, i) in workPopupContent.slide"
                  :key="i"
                  class="popup_slide"
                >
                  <img :src="item" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </transition>
      </div>
    </section>
    <!-- works 끝 -->

    <!-- contact 시작 -->
    <section class="c_section contact" id="contact">
      <div class="c_inner">
        <h1 class="c_section_title">
          Contact
        </h1>
        <article v-slide.vertical.child="{percent: '20%'}" class="profile_box">
          <div class="member">
            <div class="circle">
              <img src="@/assets/image/home/img_contact_people_01.png" alt="profile" />
            </div>
            <h2>
              CEO<br />
              정재근
            </h2>
          </div>
          <div class="member">
            <div class="circle">
              <img src="@/assets/image/home/img_contact_people_02.png" alt="profile" />
            </div>
            <h2>
              Development Head<br />
              김근성
            </h2>
          </div>
          <div class="member">
            <div class="circle">
              <img src="@/assets/image/home/img_contact_people_03.png" alt="profile" />
            </div>
            <h2>
              Game Art Head<br />
              안병웅
            </h2>
          </div>
          <div class="member">
            <div class="circle">
              <img src="@/assets/image/home/img_contact_people_04.png" alt="profile" />
            </div>
            <h2>
              Creative & Design Head<br />
              성신원
            </h2>
          </div>
          <div class="member">
            <div class="circle">
              <img src="@/assets/image/home/img_contact_people_05.png" alt="profile" />
            </div>
            <h2>
              3D ART Group Head<br />
              Sumit Sabharwal
            </h2>
          </div>
        </article>
      </div>
      <article class="company_info">
        <div class="c_inner">
          <div v-slide.vertical class="slogun">
            <h2>
              여러분의 미래<br>
              비넥츠가 함께 합니다
            </h2>
            <img class="ci" src="@/assets/image/common/logo_white.svg" alt="logo_ci" />
          </div>
          <div v-slide.child class="office_box">
            <div class="korea">
              <h2><span>한국 OFFICE</span></h2>
              <div>
                <p>10881 경기도 파주시 회동길 174 </p>
                <p>174, Hoedong-gil, Paju-si, Gyeonggi-do, Republic of Korea</p>
                <p>T. +82 31 955 1980</p>
                <p>F. +82 31 955 1981</p>
                <p>E. thewarsjjk@vinacts.com</p>
              </div>
            </div>
            <div class="india">
              <h2><span>인도 OFFICE</span></h2>
              <div>
                <p>PatelNagar, IndustrialAreaRoad, Dehradun-248001, Uttarakhand, India</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <!-- contact 끝 -->
  </div>
</template>

<script>
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/swiper-bundle.min.css"
SwiperCore.use([ Pagination, Navigation, Autoplay ]);

import YouTube from 'vue3-youtube'

export default {
  name: 'Home',
  components: {
    Swiper, SwiperSlide,
    YouTube,
  },
  computed: {
    filteredWorks(){
      if(this.currentWork === 'all'){
        return this.works;
      } else {
        return this.works.filter(el=>el.category===this.currentWork)
      }
    },
    slicedWorks(){
      if(!this.showMoreWork){
        return this.filteredWorks.slice(0, 9); // slice 길이 조정하면 위의 more 버튼 보이는 조건도 조정 필요
      } else {
        return this.filteredWorks;
      }
    },
    playerVars() {  // 유튜브 설정 변수들
      return {
        controls: 0,
        autoplay: 1,
        muted: 1,
        loop: 1,
        playlist: this.videoId,
        playsinline: 1,
        rel: 0,
        fs: 0,
        iv_load_policy: 3,
        cc_load_policy: 0,
        modestbranding: 1,
        disablekb: 1,
      };
    },
  },
  watch: {
    currentWork(){
      this.showMoreWork = false;
    }
  },
  data(){
    return{
      videoId: 'fHIn7TrGhvQ',
      isVideoReady: false,
      // videoId: 'LCetNA5tUTE',  // 테스트용 짧은 영상
      clientSwiper: null,
      clientSlideOption: {
        slidesPerView: 3,
        spaceBetween: 15,
        loop: true,
        autoplay: true,
        breakpoints: {
          599: {  //브라우저가 599보다 클 때
            slidesPerView: 5,
            spaceBetween: 10,
          },
        },
      },
      popupSlideOption: {
        slidesPerView: 1,
        loop: true,
        navigation: true,
        pagination: {
          clickable: true,
        },
      },
      currentHistory: 2022,
      histories: [
        {
          year: 2022,
          contents: [
            "현대자동차 아이오닉6 VR 글로벌캠페인",
            "미래에셋 증권 메타버스 VTS(Virtual Trading System) 플랫폼",
            "DMZ 생생누리 4면 인터랙티브 체험형 컨텐츠",
            '한국 문화재 재단 VR 애니메이션 "무동이야기"',
          ]
        },
        {
          year: 2021,
          contents: [
            "포스코 가속기 교육 (VR 교육 인터렉티브)",
            "LH 3기 신도시 360 VR (터치스크린 인터렉티브)",
            "경기도 경제과학진흥원 AI 반응형 디스플레이 (체험 인터렉티브)",
            "세계 유교선비 문화공원 의병체험관 (3면 멀티플레이 인터렉티브)",
          ]
        },
        {
          year: 2020,
          contents: [
            "고성 공룡 4D 영상 제작 (스테레오 영상)",
            "아난티호텔 Wall 모션 (립모션 인터렉티브)",
            "LG 뉴저지 곡면 Ndisplay 아쿠아리움 (인터렉션)",
            "삼성 디스플레이공장 설비 VR (VR 교육 인터렉티브)",
            "(VR 교육 인터렉티브)",
            "LH 홈스타일랩 (인테리어 인터렉티브)",
            "LH 신혼희망타운 360 공용시설 (터치스크린 인터렉티브)",
          ]
        },
        {
          year: 2019,
          contents: [
            "SK 텔레콤 AI 교통표지판 딥러닝 3D 구축 (딥러닝 3D 데이터)",
            "레드로버 태백안전체험관 눈사태 스키게임 (스키시뮬레이터 체감형게임)",
            "LH 신혼희망타운 라이프 (VR 인터렉티브)",
            "LH 신혼희망타운 게임 4종 (게임컨트롤러 게임)",
            "LH 신혼희망타운 360 모델하우스 (터치스크린 인터렉티브)",
            "프로스키 실감형 육상 게임 (2인 멀티플레이 운동게임)",
          ]
        },
        {
          year: 2018,
          contents: [
            "삼성전자  CES 갤럭시 스키 시뮬레이터 (4인멀티플레이 게임)",
            "삼성전자 CES 갤럭시 크로스컨트리 시뮬레이터 (2인멀티플레이 게임)",
            "에버랜드 VR 직업체험관 (VR 인터렉티브)",
            "프로스키 실감형 헬스케어 리듬액션 게임 (2인 멀티플레이 리듬게임)",
            "미디어프론트 뉴욕호버 (VR 라이딩 슈팅게임)",
            "미디어프론트 제천 360 돔 영상 (360Dome 영상)",
            "TE Connectivity TE 아일랜트 (VR, 립모션)",
            "미디어프론트 스톰 블리자드 (VR 리얼타임 라이딩)",
            "LH 가상현실 모델하우스 (VR 인터렉티브 모델하우스)",
          ]
        },
        {
          year: 2017,
          contents: [
            "SK 텔레콤 5G샵 (VR 인터렉티브)",
            "SK 텔레콤 트레저헌터 (VR 5m 룸스케일 게임)",
            "미디어프론트 뉴욕점프 (VR 리얼타임 라이딩)",
            "미디어프론트 정글점프 (VR 리얼타임 라이딩)",
            "미디어프론트 정글행글라이더 (VR 리얼타임 라이딩)",
          ]
        },
        {
          year: 2016,
          contents: [
            "TE Connective  행글라이더 (VR 리얼타임 라이딩)",
            "대림건설 가상현실 모델하우스 (터치TV 3D인터렉티브)",
            "대림건설 가상현실 모델하우스 (VR 인터렉티브 모델하우스)",
            "이토이랩 앨리스포커 배경제작 (VR 리얼타임 카지노게임)",
          ]
        },
      ],
      currentWork: 'all',
      showMoreWork: false,
      works: [
        // inter 시작
        {
          category: 'inter',
          title: 'Samsung _ Galaxy Fitness Ski',
          thumb: require('@/assets/image/home/works/thumb/img_inter_ski.png'),
          video: 'https://www.youtube.com/shorts/Vc8zeUQp39s',
        },
        {
          category: 'inter',
          title: 'LG _ New gersey Aquarium',
          thumb: require('@/assets/image/home/works/thumb/img_inter_aquarium.png'),
          video: '',
          slide: [
            require('@/assets/image/home/works/slide/img_slide_lg_01.jpg'),
            require('@/assets/image/home/works/slide/img_slide_lg_02.jpg'),
          ]
        },
        {
          category: 'inter',
          title: '아난티 호텔 _ 캐비네 드 쁘아쏭',
          thumb: require('@/assets/image/home/works/thumb/img_inter_hotel.png'),
          slide: [
            require('@/assets/image/home/works/slide/img_slide_hotel_01.jpg'),
            require('@/assets/image/home/works/slide/img_slide_hotel_02.jpg'),
          ]
        },
        {
          category: 'inter',
          title: 'LH _ 신혼희망타운 게임 4종',
          thumb: require('@/assets/image/home/works/thumb/img_inter_game.png'),
          video: 'https://www.youtube.com/watch?v=E5jHbl72ul0',
        },
        {
          category: 'inter',
          title: 'LH _ 가상 모델 하우스',
          thumb: require('@/assets/image/home/works/thumb/img_inter_house.png'),
          video: 'https://www.youtube.com/watch?v=W3JtZ-mq_YM',
        },
        {
          category: 'inter',
          title: 'LH _ Home Style LAB',
          thumb: require('@/assets/image/home/works/thumb/img_inter_home_lab.png'),
          video: 'https://www.youtube.com/watch?v=Zzzok5SsCCM',
        },
        // {
        //   category: 'inter',
        //   title: '대림건설 _ D.House',
        //   thumb: require('@/assets/image/home/works/thumb/img_inter_d_house.png')
        // },
        {
          category: 'inter',
          title: 'MediaFront _ Rhythm Health',
          thumb: require('@/assets/image/home/works/thumb/img_inter_rhythm.png'),
          video: 'https://www.youtube.com/watch?v=g47Uqf7xc0c',
        },

        // vr 시작
        {
          category: 'vr',
          title: 'SKT _ 5G Shop',
          thumb: require('@/assets/image/home/works/thumb/img_vr_skt_5g.png'),
          video: 'https://www.youtube.com/watch?v=YXiwAoRByC8',
        },
        {
          category: 'vr',
          title: 'MediaFront _ Storm Blizzard ',
          thumb: require('@/assets/image/home/works/thumb/img_vr_blizzard.png'),
          video: '',
        },
        {
          category: 'vr',
          title: 'MediaFront _ Jungle Jump',
          thumb: require('@/assets/image/home/works/thumb/img_vr_jungle_jump.png'),
          video: 'https://www.youtube.com/watch?v=Z2NX-JabASc',
        },
        {
          category: 'vr',
          title: 'MediaFront _ Jiungle Hang Gliding',
          thumb: require('@/assets/image/home/works/thumb/img_vr_hang_gliding.png'),
          video: 'https://www.youtube.com/watch?v=E0tgBuYTDMo',
        },
        {
          category: 'vr',
          title: 'MediaFront _ Newyork Jump',
          thumb: require('@/assets/image/home/works/thumb/img_vr_newyork_jump.png'),
          video: 'https://www.youtube.com/watch?v=CEhveHI8SnE',
        },
        {
          category: 'vr',
          title: 'MediaFront _ Newyork Hover',
          thumb: require('@/assets/image/home/works/thumb/img_vr_newyork_hover.png'),
          video: 'https://www.youtube.com/watch?v=T2csDtbEq0I',
        },
        {
          category: 'vr',
          title: 'TE Connective _ How it works',
          thumb: require('@/assets/image/home/works/thumb/img_vr_howit.png'),
          video: 'https://www.youtube.com/watch?v=d3AknQ9ihPQ',
        },

        // movie 시작
        {
          category: 'movie',
          title: '경남고성공룡세계엑스포 _ Dinosaur Planet',
          thumb: require('@/assets/image/home/works/thumb/img_movie_expo.png'),
          video: 'https://www.youtube.com/watch?v=le_zeSBx2vM',
        },
        // {
        //   category: 'movie',
        //   title: 'MediaFront _ 다시,지구',
        //   thumb: require('@/assets/image/home/works/thumb/img_movie_360.png')
        // },

      ],
      workPopupContent: null,
    }
  },
  mounted(){
    setTimeout(() => {
      this.clientSwiper?.update();  // ctrl+shift+r 했을때 스와이퍼 깨지는거 방지
    }, 0);
  },
  methods: {
    onSwiper(swiper){
      this.clientSwiper = swiper;
    },
    youtubeReady() {
      // this.$refs.youtube.setPlaybackQuality(
      //   window.innerWidth<599 ? 'large'
      //   : window.innerWidth<768 ? 'hd720'
      //   : 'hd1080'
      // )
      this.$refs.youtube.mute()
      this.$refs.youtube.playVideo()
      this.isVideoReady = true;
    },
    openWorkPopup(item){
      if(item.video){
        window.open(item.video, '_blank');
      } else {
        this.workPopupContent = item;
      }
    },
    closeWorkPopup(){
      this.workPopupContent = null;
    },
    preventScroll(e){
      e.preventDefault();
      e.stopPropagation();
    }
  },
}
</script>

<style scoped src="@/assets/css/views/home.css">
</style>