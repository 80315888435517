<template>
  <!-- <ToTop /> -->
  <Header />

  <router-view/>

  <Footer />
</template>

<script>
// import ToTop from '@/components/ToTop';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  components:{
    // ToTop,
    Header,
    Footer,
  }  
}
</script>

<style>
@import url('./assets/css/common/reset.css');
@import url('./assets/css/common/common.css');
</style>
