
function init(el){
    const childrenEl = el.childNodes;
    for(let i=0; i<childrenEl.length; i++){
        childrenEl[i].classList.add('circleMotion')
        childrenEl[i].classList.remove('on')
    }
}

// v-circleMotionCustom
// v-circleMotionCustom:keep="{ delay: 0 }"
const slide = {
    beforeMount(el) {  // 초기화
        init(el,);
    },
    mounted(el, binding) {
        function motion() { // 모션
            const childrenEl = el.childNodes;
            for(let i=0; i<childrenEl.length/2; i++){
                setTimeout(() => {
                    childrenEl[i].classList.add('on')
                }, i*(binding.value?.delay ?? 250));
                setTimeout(() => {
                    childrenEl[childrenEl.length -i -1].classList.add('on')
                }, i*(binding.value?.delay ?? 250));
            }
        }

        function createObserver() { // InsertionObserver 생성
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        if(binding.arg != 'keep'){
                            observer.unobserve(el); // keep 아니면 관찰할 필요 x
                        }
                    } else {    // 감지대상이 교차영역에서 나간 경우
                        if(binding.arg == 'keep'){
                            init(el, binding);
                        }
                    }
                });
            }, {
                rootMargin: binding.value?.rootMargin ?? '0% 0px -10%',
                threshold: binding.value?.threshold ?? 0.1,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    }
};

export default slide;
