
function init(el, binding){
    if(binding.modifiers.child){    // 자식 요소에 차례로 효과 넣을 때
        const childrenEl = el.childNodes;
        for(let i=0; i<childrenEl.length; i++){
            childrenEl[i].classList.add('circleMotion')
            childrenEl[i].classList.remove('on')
        }
    } else {    // 해당 요소에 직접 효과 넣을 때
        el.classList.add('circleMotion');
        el.classList.remove('on')
    }
}

// v-circleMotion
// v-circleMotion:keep.child="{ delay: 0 }"
const slide = {
    beforeMount(el, binding) {  // 초기화
        init(el, binding);
    },
    mounted(el, binding) {
        function motion() { // 모션
            if(binding.modifiers.child){    // 자식 요소에 차례로 효과 넣을 때
                const childrenEl = el.childNodes;
                for(let i=0; i<childrenEl.length; i++){
                    setTimeout(() => {
                        childrenEl[i].classList.add('on')
                    }, i*(binding.value?.delay ?? 200));
                }
            } else {    // 해당 요소에 직접 효과 넣을 때
                setTimeout(() => {
                    el.classList.add('on');
                }, binding.value?.delay ?? 0);
            }
        }

        function createObserver() { // InsertionObserver 생성
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        if(binding.arg != 'keep'){
                            observer.unobserve(el); // keep 아니면 관찰할 필요 x
                        }
                    } else {    // 감지대상이 교차영역에서 나간 경우
                        if(binding.arg == 'keep'){
                            init(el, binding);
                        }
                    }
                });
            }, {
                rootMargin: binding.value?.rootMargin ?? '0% 0px -8%',
                threshold: binding.value?.threshold ?? 0.05,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    }
};

export default slide;
