<template>
  <footer>
		<div class="c_inner">
      <p>Copyright © 2022 VINACTS, Inc. All rights reserved</p>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
footer{
  background-color: var(--base-color-dark);
  padding: var(--margin-mid) 0;
}
footer p{
  opacity: 0.3;
}
</style>